import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Spa Dentistry in Toronto – Relaxation Dentistry | The Smile Stylist" 
    description="The Smile Stylist is a 5 Star Cosmetic Dentistry Clinic that creates a relaxing spa like environment in order to enhance our patients experience & help deliver results." 
    keywords="without pain, dental services ca, smilestylist, dr kaloti, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="coming-soon proper hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>The Ultimate in Relaxation Dentistry</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="info__section_spa-bg" id="main-white">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 wow animated fadeInRight">
            <p className="info__text_black">We believe that creating a relaxing spa like environment that offers a gentler, more pleasurable approach will not only enhance our patients experience but help create even greater results.  At the <a href="/">Smile Stylist</a> we are always looking for ways to enhance your experience so that you leave with a big smile on your face. </p>
          </div>
        </div>
        <p className="info__text_bold_right">Patient Perks</p>
        <div className="container">
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-5 wow animated fadeInRight">
              <img alt=" Hot Towels " className="info__img-item" src={ require("../images/desktop/services/hot-towels.png" )} />
              <p className="info__img-sub">Hot Towels</p>
            </div>
            <div className="col-sm-5 wow animated fadeInRight">
              <img alt="Base Headsets" className="info__img-item" src={ require("../images/desktop/services/bose-headsets.png" )} />
              <p className="info__img-sub">Bose Headsets</p>
            </div>
            <div className="col-sm-1"></div>
          </div>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-5 wow animated fadeInRight">
              <img alt="Netflix" className="info__img-item" src={ require("../images/desktop/services/netflix.jpg" )} />
              <p className="info__img-sub">Netflix & FIbe TV during Procedures</p>
            </div>
            <div className="col-sm-5 wow animated fadeInRight">
              <img alt="Neck Pillows" className="info__img-item" src={ require("../images/desktop/services/neck-pillows.jpg" )} />
              <p className="info__img-sub">Neck Pillows</p>
            </div>
            <div className="col-sm-1"></div>
          </div>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-5 wow animated fadeInRight">
              <img alt="Complimentary Refreshments "className="info__img-item" src={ require("../images/desktop/services/refreshments.png" )} />
              <p className="info__img-sub">Complimentary Refreshments</p>
            </div>
            <div className="col-sm-5 wow animated fadeInRight">
              <img alt="Complimentary Blankets" className="info__img-item" src={ require("../images/desktop/services/blankets.png" )} />
              <p className="info__img-sub">Complimentary Blankets</p>
            </div>
            <div className="col-sm-1"></div>
          </div>
          <a className="btn info__btn-bottom" href="/contact"><span>Book Consultation</span></a>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage